import * as React from 'react';
import { Button, TextField } from '@mui/material';
import axios from 'axios';


const Yukon_Exam: React.FC = () => {
    const [rawInfo, setRawInfo] = React.useState<string>("")
    const [isDone, setIsDone] = React.useState<boolean>(true)
    const [yukonAnswer, setYukonAnswer] = React.useState<string>("")
    const [bingCookie, setBingCookie] = React.useState<string>("")
    const getYukonExamAnswer = () => {
        try {
            setIsDone(false)
            axios.post("http://api.weightloss123.xyz/getYukonExamAnswer", {
                cookie: bingCookie,
                info: rawInfo
            }).then(res => {
                setYukonAnswer(res.data.toString())
            }).catch(error => {
                setYukonAnswer("Lỗi")
                console.log(error)
            }).finally(() => {
                setIsDone(true)

            })
        }
        catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <TextField label="Thông tin" multiline maxRows={20} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRawInfo(event.target.value)
            }} />
            <TextField label="Bing Cookie" multiline maxRows={20} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBingCookie(event.target.value)
            }} />
            <Button variant='contained' onClick={getYukonExamAnswer} disabled={!isDone}>{isDone ? 'Lấy đáp án' : 'Đang thực hiện'}</Button>
            <TextField value={yukonAnswer} multiline maxRows={20} />
        </>
    )
}
export default Yukon_Exam