import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AccountCircle, EmailSharp } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

enum UserStatus {
  NoInfo,
  Processing,
  Error,
  Done,
}

interface Message {
  seqno: number;
  headers: {
    from: string;
    subject: string;
    date: Date;
  };
  data: {
    html: string;
  };

  deliverTree: string;
}

interface User {
  username: string;
  password: string;
  messages: Message[];
  status: UserStatus;
  code: string;
  fullName: string[]
}

interface RowComponentProps {
  user: User;
  op: (nn: string) => void;
}

const Row: React.FC<RowComponentProps> = ({ user, op }) => {
  const [open, setOpen] = useState(false);
  const handleClick = (nn: string) => {
    op(nn);
  };
  const convertDateToVietnameseDate = (date: Date) => {
    return `${date.getHours()}:${date.getMinutes()}, ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  const statusColors = {
    [UserStatus.NoInfo]: 'white',
    [UserStatus.Processing]: 'blue',
    [UserStatus.Done]: 'green',
    [UserStatus.Error]: 'red',
  };

  // sx={
  //   {
  //     '& > *':
  //       { borderBottom: 'unset' },
  //     "& th": {
  //       // fontSize: "2.25rem",
  //       color: "rgba(255, 255, 255)"
  //     }
  //   }}
  //   hover={true}
  //   style={{
  //     backgroundColor: user.status === UserStatus.NoInfo ? 'white' : user.status === UserStatus.Processing ? 'blue' : user.status === UserStatus.Done ? 'green' : 'red'
  //   }} 
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: statusColors[user.status] || 'white',
          "& th": {
            color: "rgba(255, 255, 255)"
          },
          // "& th": {
          //   color: statusColors[user.status] || 'black'
          // },
        }}
        onClick={() => setOpen(!open)}

      >
        {/* <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => setOpen(!open)}
          >
          </IconButton>
        </TableCell> */}
        <TableCell component="th" scope="row">
          {user.username}
        </TableCell>
        <TableCell component="th" scope="row">{user.password}</TableCell>
        <TableCell component="th" scope="row">{user.messages.length}</TableCell>
        <TableCell component="th" scope="row">{(user.messages.length > 0) ? `${user.messages[0].headers.from} | ${user.messages[0].headers.subject}` : null}</TableCell>
        <TableCell component="th" scope="row">{user.code}</TableCell>
        <TableCell component="th" scope='row'>{user.fullName.join(', ')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '16px 0' }}>
              <Typography variant="h6" gutterBottom component="div">
                Danh sách tin nhắn
              </Typography>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>STT</TableCell>
                    <TableCell>Người gửi</TableCell>
                    <TableCell>Ngày gửi</TableCell>
                    <TableCell>Cây chuyển tiếp</TableCell>
                    <TableCell>Tiêu đề</TableCell>
                    <TableCell>Nội dung</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.messages.map((message) => (
                    <TableRow key={message.seqno} hover={true} sx={{
                      '&:nth-of-type(odd)': {
                        'background-color': '#DDDDDD'
                      },
                      ':&:nth-of-type(even)': {
                        'background-color': '#999999'
                      },
                      '& > td': {
                        'color': 'black'
                      }
                    }}>
                      <TableCell component="th" scope="row">
                        {message.seqno}
                      </TableCell>
                      <TableCell>{message.headers.from}</TableCell>
                      <TableCell>{convertDateToVietnameseDate(message.headers.date)}</TableCell>
                      <TableCell>{message.deliverTree}</TableCell>
                      <TableCell>{message.headers.subject}</TableCell>
                      <TableCell><Button onClick={() => handleClick(message.data.html)}>Đọc thư</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const App: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [listEmailWithPassword, setListEmailWithPassword] = useState<string>("");
  const [isDone, setIsDone] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModalText, setCurrentModalText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [negaSearchText, setNegaSearchText] = useState("")

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const op = (nn: string) => {
    handleOpen();
    setCurrentModalText(nn);
  };

  const filteredUsers = users.map(user => {
    let filteredMessages = user.messages;

    if (user.status === UserStatus.Done) {
      filteredMessages = filteredMessages.filter(message =>
        message?.headers?.subject?.toLowerCase().includes(searchText.trim().toLowerCase())
      )
      if (negaSearchText.trim() !== "") {
        filteredMessages = filteredMessages.filter(message =>
          !message?.headers?.subject?.toLowerCase().includes(negaSearchText.trim().toLowerCase())
        )
      }
    }

    return {
      ...user,
      messages: filteredMessages,
    };
    // }).filter(user => user.status !== UserStatus.Done);
  }).filter(user => user.messages.length >= 0 || user.status !== UserStatus.Done);

  const getEmailList = () => {
    try {
      setIsDone(false);
      let listTask: Promise<any>[] = [];
      setUsers([]);
      listEmailWithPassword.split('\n').forEach(emailWithPassword => {
        let emailWithPasswordSplited = emailWithPassword.split("|");
        if (emailWithPasswordSplited.length === 2) {
          let username = emailWithPasswordSplited[0];
          let password = emailWithPasswordSplited[1];
          setUsers((prev) => [...prev, {
            username: username,
            password: password,
            messages: [],
            status: UserStatus.Processing,
            code: '', fullName: []
          }]);
          listTask.push(axios
            // .post('http://localhost:6997/getEmail',{'username': username, 'password': password})
            .post('https://api.weightloss123.xyz/getEmail',{'username': username, 'password': password})
            .then(result => {
              setUsers((prev) => {
                const updatedData = prev.map((item) => {

                  if (item.username === result.data.username && item.password === result.data.password) {
                    // console.log(result.data.messages.map((message: Message) => ({
                    //   ...message,
                    //   headers: {
                    //     ...message.headers,
                    //     date: new Date(message.headers.date),
                    //   },
                    // })));

                    return {
                      ...item, messages: result.data.messages.map((message: Message) => ({
                        ...message,
                        headers: {
                          ...message.headers,
                          date: new Date(message.headers.date),
                        },
                      })),
                      status: UserStatus.Done,
                      code: result.data.code, fullName: result.data.fullName
                    };
                  }
                  return item;
                });
                return updatedData;
              });
            })
            .catch(error => {
              setUsers((prev) => {
                const updatedData = prev.map((item) => {
                  if (item.username === username && item.password === password) {
                    return {
                      ...item, status: UserStatus.Error,
                    };
                  }
                  return item;
                });
                return updatedData;
              });
              console.log(error);
            }));
        }
      });
      Promise.all(listTask).then(() => setIsDone(true));
    }
    catch (error) {
      console.error(error);
    }
  };

  return (
    // <React.Fragment>
    <Box sx={{ margin: '20px' }}>
      <Link to="yukonExam">Yukon Exam Part 2</Link>
      <Link to="yukonPart3">Yukon Part 3</Link>
      <TextField
        multiline
        maxRows={4}
        fullWidth
        label="List Email"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setListEmailWithPassword(event.target.value);
        }}
        sx={{ margin: '16px 0' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <EmailSharp />
            </InputAdornment>
          )
        }}
      />
      <TextField
        label="Tiêu đề chứa"
        value={searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
        sx={{ margin: '16px 10px' }}
      />
      <TextField
        label="Tiêu đề không chứa"
        value={negaSearchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNegaSearchText(event.target.value);
        }}
        sx={{ margin: '16px 10px' }}
      />
      <Button
        variant='contained'
        onClick={getEmailList}
        disabled={!isDone}
        sx={{ margin: '16px 10px', backgroundColor: '#f702e7', '&:hover': { backgroundColor: '#b302a7' } }}
      >
        {isDone ? 'Đọc inbox' : 'Đang thực hiện'}
      </Button>
      <TableContainer component={Paper}
      // sx={{ margin: '5px' }}
      >
        <Table aria-label="collapsible table" size='small'>
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell>Username</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Số tin nhắn</TableCell>
              <TableCell>Người gửi gần nhất</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Full Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map(user => (
              <Row key={user.username} user={user} op={op} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: '80%',
            overflow: 'auto',
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: currentModalText }} />
        </Box>
      </Modal>
    </Box>

    // </React.Fragment>
  );
};

export default App;
