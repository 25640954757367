import React, { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
    Button,
    TextField,
    Stack,
    Container,
    Box,
    Card,
    CardContent,
    Typography,
} from '@mui/material';

interface LinkData {
    code: string;
    text: string;
    link: string[];
}

interface ProcessedLinkData {
    code: string;
    text: string[];
    link: string[];
}

const AnalyzerApp: React.FC = () => {
    const [userQuery, setUserQuery] = useState<string>('');
    const [userLocale, setUserLocale] = useState<string>('');
    const [userLocation, setUserLocation] = useState<string>('');
    const [rawData, setRawData] = useState<LinkData[]>([]);
    const [processedData, setProcessedData] = useState<ProcessedLinkData[]>([]);
    const [prompt, setPrompt] = useState<string>("")

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'userQuery') {
            setUserQuery(value);
        } else if (name === 'userLocale') {
            setUserLocale(value);
        } else if (name === 'userLocation') {
            setUserLocation(value);
        }
    };

    const handleRawDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        try {
            const parsedData = JSON.parse(event.target.value);
            if (Array.isArray(parsedData)) {
                setRawData(parsedData);
            }
        } catch (error) {
            console.error('Invalid JSON format');
        }
    };

    const processLinkData = async (linkData: LinkData): Promise<ProcessedLinkData> => {
        try {
            const formData = new FormData();
            formData.append("language", "eng");
            formData.append("isOverlayRequired", "false");
            formData.append("base64Image", linkData.text);
            formData.append("iscreatesearchablepdf", "false");
            formData.append("issearchablepdfhidetextlayer", "false");

            const requestConfig = {
                method: "post",
                url: "https://api.ocr.space/parse/image",
                headers: {
                    "Content-Type": "multipart/form-data",
                    apikey: "K8688957",
                },
                data: formData,
            };
            const response: AxiosResponse<any> = await axios(requestConfig)

            return { code: linkData.code, text: [response.data.ParsedResults[0].ParsedText], link: linkData.link }
        } catch (error) {
            console.error('Error processing linkData:', error);
            throw error;
        }
    };

    const handleAnalyzeClick = async () => {
        setProcessedData([]);
        const processedResults = await Promise.all(rawData.map((data) => processLinkData(data)));
        setProcessedData(processedResults);
    };

    const exportPromptClick = () => {
        setPrompt(JSON.stringify(processedData))
        // processedData.forEach(dat=>{
        //     setPrompt
        // })
    }

    return (
        <Container maxWidth="lg">
            <Stack spacing={2} sx={{ mt: 4 }}>
                <TextField
                    label="User Query"
                    name="userQuery"
                    value={userQuery}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    label="User Locale"
                    name="userLocale"
                    value={userLocale}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    label="User Location"
                    name="userLocation"
                    value={userLocation}
                    onChange={handleInputChange}
                    fullWidth
                />
                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="Raw Data (JSON)"
                        multiline
                        rows={5}
                        value={JSON.stringify(rawData, null, 2)}
                        onChange={handleRawDataChange}
                        fullWidth
                    />
                </Box>
                <Button variant="contained" onClick={handleAnalyzeClick} fullWidth>
                    Analyze
                </Button>
            </Stack>
            <Box sx={{ mt: 4 }}>
                {processedData.map((data) => (
                    <Block key={data.code} data={data} onUpdateData={(updatedData) => {
                        setProcessedData(prevData => prevData.map(item => item.code === updatedData.code ? updatedData : item))
                    }} />
                ))}
            </Box>
            <Stack spacing={2} sx={{ mt: 4 }}>
                {/* <TextField
                    label="User Query"
                    name="userQuery"
                    value={userQuery}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    label="User Locale"
                    name="userLocale"
                    value={userLocale}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    label="User Location"
                    name="userLocation"
                    value={userLocation}
                    onChange={handleInputChange}
                    fullWidth
                />
                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="Raw Data (JSON)"
                        multiline
                        rows={5}
                        value={JSON.stringify(rawData, null, 2)}
                        onChange={handleRawDataChange}
                        fullWidth
                    />
                </Box> */}
                <Button variant="contained" onClick={exportPromptClick} fullWidth>
                    Export Prompt
                </Button>
                <TextField label="Prompt" multiline rows={5} value={prompt} fullWidth></TextField>
            </Stack>
        </Container>
    );
};

interface BlockProps {
    data: ProcessedLinkData;
    onUpdateData: (updatedData: ProcessedLinkData) => void;
}
const Block: React.FC<BlockProps> = ({ data, onUpdateData }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState<ProcessedLinkData>({ ...data });
    const [editedLinkList, setEditedLinkList] = useState<string[]>([...data.link]);
    const [editedTextList, setEditedTextList] = useState<string[]>([...data.text]);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedData({ ...data });
        setEditedLinkList([...data.link]);
        setEditedTextList([...data.text]);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        setEditedData((prevData) => ({ ...prevData, link: [...editedLinkList], text: [...editedTextList] }));
        onUpdateData({ ...editedData, link: [...editedLinkList], text: [...editedTextList] });
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleLinkChange = (index: number, value: string) => {
        setEditedLinkList((prevList) => {
            const newList = [...prevList];
            newList[index] = value;
            return newList;
        });
    };

    const handleAddLink = () => {
        setEditedLinkList((prevList) => [...prevList, '']);
    };

    const handleRemoveLink = (index: number) => {
        setEditedLinkList((prevList) => {
            const newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        });
    };

    const handleAddText = () => {
        setEditedTextList((prevList) => [...prevList, '']);
    };

    const handleRemoveText = (index: number) => {
        setEditedTextList((prevList) => {
            const newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        });
    };

    const handleEditText = (index: number, value: string) => {
        setEditedTextList((prevList) => {
            const newList = [...prevList];
            newList[index] = value;
            return newList;
        });
    };

    return (
        <Card variant="elevation" sx={{ mt: 4, mb: 4 }}>
            <CardContent>
                {isEditing ? (
                    <>
                        <TextField
                            label="Code"
                            name="code"
                            value={editedData.code}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        {editedTextList.map((text, index) => (
                            <Box key={index}>
                                <TextField
                                    label={`Text ${index + 1}`}
                                    value={text}
                                    onChange={(event) => handleEditText(index, event.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                />
                                <Button variant="contained" color="error" onClick={() => handleRemoveText(index)}>
                                    Remove Text
                                </Button>
                            </Box>
                        ))}
                        <Button variant="contained" onClick={handleAddText} sx={{ mt: 1 }}>
                            Add Text
                        </Button>
                        {editedLinkList.map((link, index) => (
                            <Box key={index}>
                                <TextField
                                    label={`Link ${index + 1}`}
                                    value={link}
                                    onChange={(event) => handleLinkChange(index, event.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                                <Button variant="contained" color="error" onClick={() => handleRemoveLink(index)}>
                                    Remove Link
                                </Button>
                            </Box>
                        ))}
                        <Button variant="contained" onClick={handleAddLink} sx={{ mt: 1 }}>
                            Add Link
                        </Button>
                        <Stack direction="row" spacing={2} mt={2}>
                            <Button variant="contained" color="success" onClick={handleSaveClick}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Stack>

                    </>
                ) : (
                    <>
                        <Typography variant="h6" component="div">
                            Code: {data.code}
                        </Typography>
                        {editedTextList.map((text, index) => (
                            <Typography variant="body1" gutterBottom key={index}>
                                Text {index + 1}: {text}
                            </Typography>
                        ))}
                        <Typography variant="body2" color="textSecondary" component="div">
                            Link:
                        </Typography>
                        <ul>
                            {data.link.map((link, index) => (
                                <li key={index}>{link}</li>
                            ))}
                        </ul>
                        <Button variant="contained" onClick={handleEditClick}>
                            Edit
                        </Button>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default AnalyzerApp;
